<template>
    <DatePicker class="width_300" :transfer="true" :value="value || ''" :style="{ width: width + 'px' }" :editable="false" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择" @on-change="onChangeDate"></DatePicker>
</template>
<script>
const logTitle = 'myDate';
export default {
    name: 'myDate',
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        width: {
            type: Number,
            required: false,
            default: 150,
        },
    },
    data() {
        return {
            date: null,
        };
    },
    methods: {
        onChangeDate(v1) {
            this.date = v1;
            console.log(logTitle, 'date：' + v1);
            this.$emit('input', v1);
        },
    },
};
</script>
<style lang="less" scoped></style>
